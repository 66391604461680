import React, { useState } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import jsPDF from 'jspdf';
import "jspdf-autotable";
import moment from 'moment-timezone'; // Import moment untuk format waktu
import './css/datagajicabang.css';

axios.defaults.withCredentials = true;

const getApiBaseUrl = () => {
    const protocol = window.location.protocol === 'https:' ? 'https' : 'http';
   const baseUrl = process.env.REACT_APP_API_BASE_URL.replace(/^https?:\/\//, '');
    return `${protocol}://${baseUrl}`;
  };

const fetcher = (url) => axios.get(url).then(res => res.data);

// Fungsi untuk memformat angka menjadi format Rupiah
const formatRupiah = (angka) => {
  if (angka == null) return 'Rp0';
  return 'Rp' + angka.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const DataGajiCabang = () => {
    const [bulan, setBulan] = useState(moment().format('MM')); // Inisialisasi bulan saat ini
    const [tahun, setTahun] = useState(moment().format('YYYY')); // Inisialisasi tahun saat ini
    const [fetchData, setFetchData] = useState(false);
    const [warning, setWarning] = useState('');

    const { data: gaji, error } = useSWR(
        fetchData ? `${getApiBaseUrl()}/getgajibycabang?bulan=${bulan}&tahun=${tahun}` : null,
        fetcher
    );

    const handleMonthChange = (e) => {
        const [year, month] = e.target.value.split("-");
        setBulan(month);
        setTahun(year);
    };

    const handleTampilkanData = () => {
        if (!bulan || !tahun) {
            setWarning('Silahkan pilih bulan dan tahun.');
        } else {
            setWarning('');
            setFetchData(true);
        }
    };

    const handleExportPDF = (cabangId) => {
        const cabang = gaji[cabangId];
        const doc = new jsPDF({ orientation: 'landscape' });

        // Judul di bagian atas
        doc.text(`Data Gaji PT.BR Solusindo ${cabang.cabang.nama_cabang} - ${bulan}/${tahun}`, 14, 10);

        // Header tabel
        const tableColumn = ["Nama", "Periode", "Nominal", "Tambahan", "Potongan", "Jumlah", "Keterangan", "Status"];
        const tableRows = [];

        // Format data untuk PDF
        cabang.gaji.forEach((item) => {
            const rowData = [
                item.Karyawan.nama_lengkap,
                item.periode,
                formatRupiah(item.nominal),
                formatRupiah(item.tambahan),
                formatRupiah(item.potongan),
                formatRupiah(item.jumlah),
                item.keterangan,
                item.status
            ];
            tableRows.push(rowData);
        });

        // Generate tabel di PDF
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 20,
            theme: 'grid',
            styles: { fontSize: 8 },
        });

        // Menambahkan teks "Semarang, tanggal - bulan - tahun"
        const pageWidth = doc.internal.pageSize.width;
        const formattedDate = moment().tz('Asia/Jakarta').format('DD MMMM YYYY'); // Format tanggal
        doc.text(`Semarang, ${formattedDate}`, pageWidth - 100, doc.previousAutoTable.finalY + 30);

        // Tanda tangan
        doc.text('_____________', pageWidth - 80, doc.previousAutoTable.finalY + 55);
        doc.text('     Wahono', pageWidth - 80, doc.previousAutoTable.finalY + 65);

        // Save file PDF
        doc.save(`gaji-${cabang.cabang.nama_cabang}-${bulan}-${tahun}.pdf`);
    };

    return (
        <div className="container">
            <form className="form" onSubmit={(e) => e.preventDefault()}>
                <label>
                    Bulan:
                    <input
                        type="month"
                        value={`${tahun}-${bulan}`}
                        onChange={handleMonthChange}
                        placeholder="YYYY-MM"
                    />
                </label>
                <button type="button" className="btn btn-primary" onClick={handleTampilkanData}>
                    Tampilkan Data
                </button>
            </form>

            {warning && <div className="warning">{warning}</div>}

            {fetchData && error && (
                <div>{error.response?.status === 404 ? 'Data kosong untuk bulan dan tahun yang dipilih.' : 'Terjadi kesalahan saat mengambil data.'}</div>
            )}

            {gaji && Object.keys(gaji).map((key) => {
                const cabang = gaji[key];
                return (
                    <div key={key} className="card">
                        <h3>{cabang.cabang.nama_cabang}</h3>
                        <div className="scroll-container">
                            <div className="scrollable-container">
                                <table className="scrollable-table">
                                    <thead>
                                        <tr>
                                            <th>Nama</th>
                                            <th>Periode</th>
                                            <th>Nominal</th>
                                            <th>Tambahan</th>
                                            <th>Potongan</th>
                                            <th>Jumlah</th>
                                            <th>Keterangan</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cabang.gaji.map((item) => (
                                            <tr key={item.id}>
                                                <td>{item.Karyawan.nama_lengkap}</td>
                                                <td>{item.periode}</td>
                                                <td>{formatRupiah(item.nominal)}</td>
                                                <td>{formatRupiah(item.tambahan)}</td>
                                                <td>{formatRupiah(item.potongan)}</td>
                                                <td>{formatRupiah(item.jumlah)}</td>
                                                <td>{item.keterangan}</td>
                                                <td>{item.status}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <button className="btn btn-export" onClick={() => handleExportPDF(key)}>Export to PDF</button>
                    </div>
                );
            })}
        </div>
    );
};

export default DataGajiCabang;
