import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
const getApiBaseUrl = () => {
    const protocol = window.location.protocol === 'https:' ? 'https' : 'http';
   const baseUrl = process.env.REACT_APP_API_BASE_URL.replace(/^https?:\/\//, '');
    return `${protocol}://${baseUrl}`;
  };

export const ProfileAdmin = () => {
    const [profile, setProfile] = useState({});
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`${getApiBaseUrl()}/Me`,{withCredentials:true});
                setProfile(response.data);
            } catch (error) {
                setMessage(error.message);
            }
        };

        fetchProfile();
    }, []);

    return (
             <div className="card is-shadowless">
      <div className="table-container">
      
        <table className="table is-striped is-fullwidth">
                
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Nama</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>{profile.name}</td>
                        <td>{profile.email}</td>
                        <td>{profile.role}</td>
                        <td>
          <Link to={`/admin/edit/${profile.id}`} className="button is-small is-info">
            Edit
          </Link>
         
        </td>
                    </tr>
                </tbody>
            </table>
            {message && <p>{message}</p>}
        </div>
        </div>
    );
};
