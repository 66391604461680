import React, { useState } from 'react'; 
import axios from 'axios';
import useSWR from 'swr';
import { Table, Modal, Button, Row, Col, Form } from 'react-bootstrap'; 
import "../admin/css/dataabsenharian.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Lokasi from './lokasi';

axios.defaults.withCredentials = true;

const getApiBaseUrl = () => {
  const protocol = window.location.protocol === 'https:' ? 'https' : 'http';
 const baseUrl = process.env.REACT_APP_API_BASE_URL.replace(/^https?:\/\//, '');
  return `${protocol}://${baseUrl}`;
};



const fetcher = (url) => axios.get(url).then(res => res.data);

const DataAbsenHarian = () => {
  const { data, error } = useSWR(`${getApiBaseUrl()}/absensihari/get`, fetcher);
  const [showModal, setShowModal] = useState(false);
  const [selectedAbsen, setSelectedAbsen] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // State untuk search

  if (error) return <div>Error loading data...</div>;
  if (!data) return <div>Loading...</div>;

  const { absensiHarian } = data;

  // Mengelompokkan absensi berdasarkan cabang
  const absensiPerCabang = absensiHarian.reduce((acc, absen) => {
    const cabang = absen.karyawan.Cabang.nama_cabang;
    if (!acc[cabang]) {
      acc[cabang] = [];
    }
    acc[cabang].push(absen);
    return acc;
  }, {});

  // Filter berdasarkan cabang yang sesuai dengan input pencarian
  const filteredCabang = Object.keys(absensiPerCabang).filter(cabang => 
    cabang.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleRowClick = (absen) => {
    setSelectedAbsen(absen);
    setShowModal(true);
  };

  return (
    <>
      <div className="search-bar" style={{ marginBottom: '20px' }}>
        <Form.Control 
          type="text" 
          placeholder="Cari berdasarkan cabang..." 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term saat pengguna mengetik
        />
      </div>

      <div className="table-responsive">
        {filteredCabang.length > 0 ? (
          filteredCabang.map((cabang, cabangIndex) => (
            <div key={cabangIndex}>
              <p>{cabang}</p>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama Karyawan</th>
                    <th>Tanggal Absensi</th>
                    <th>Jam Masuk</th>
                    <th>Jam Keluar</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {absensiPerCabang[cabang].map((absen, index) => (
                    <tr key={absen.id} onClick={() => handleRowClick(absen)}>
                      <td>{index + 1}</td>
                      <td>{absen.karyawan.nama_lengkap}</td>
                      <td>{new Date(absen.tgl_absensi).toLocaleDateString('id-ID', { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
                      <td>{absen.jam_masuk}</td>
                      <td>{absen.jam_keluar || 'Belum absen keluar'}</td>
                      <td><Button onClick={() => handleRowClick(absen)}>Lihat Detail</Button></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ))
        ) : (
          <p>Tidak ada data yang sesuai dengan pencarian "{searchTerm}".</p>
        )}
      </div>

      <Modal 
  show={showModal} 
  onHide={() => setShowModal(false)} 
  centered
  backdrop="static" // Menjaga backdrop modal agar tidak tertutup
  keyboard={false} // Disable close modal on keyboard events
  style={{ zIndex: 1050 }} // Meningkatkan z-index modal agar di atas elemen lain
>
  <Modal.Header closeButton>
    <Modal.Title>Detail Absensi</Modal.Title>
  </Modal.Header>
  <Modal.Body 
    style={{ 
      maxHeight: '400px', // Set a maximum height
      overflowY: 'auto', // Enable vertical scroll
    }}
  >
    {selectedAbsen && (
      <>
        <Row>
          <Col md={6}>
            <p><strong>Nama Karyawan:</strong> {selectedAbsen.karyawan.nama_lengkap}</p>
            <p><strong>Nama Cabang:</strong> {selectedAbsen.karyawan.Cabang.nama_cabang}</p>
            <p><strong>Tanggal Absensi:</strong> {new Date(selectedAbsen.tgl_absensi).toLocaleDateString('id-ID', { day: '2-digit', month: '2-digit', year: 'numeric' })}</p>
            <p><strong>Jam Masuk:</strong> {selectedAbsen.jam_masuk}</p>
            <p><strong>Jam Keluar:</strong> {selectedAbsen.jam_keluar || 'Belum absen keluar'}</p>
            <p><strong>Foto Masuk:</strong></p>
            {selectedAbsen.foto_masuk ? (
              <img 
                src={`${getApiBaseUrl()}/uploads/absensi/${selectedAbsen.foto_masuk}`} 
                alt="Foto Masuk" 
                width="100%" 
                style={{ borderRadius: '5px', marginBottom: '10px' }} 
              />
            ) : (
              <p>Foto Masuk tidak tersedia</p>
            )}
          </Col>
          <Col md={6}>
            <p><strong>Lokasi Masuk:</strong></p>
            {selectedAbsen.lokasi_masuk && (
              <Lokasi
                latitude={parseFloat(selectedAbsen.lokasi_masuk.split(',')[0])}
                longitude={parseFloat(selectedAbsen.lokasi_masuk.split(',')[1])}
                style={{ width: '100%', height: '300px', borderRadius: '5px', marginBottom: '10px' }}  // Adjust peta size
              />
            )}
          </Col>
        </Row>
      </>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModal(false)}>
      Tutup
    </Button>
  </Modal.Footer>
</Modal>

</>
  );
};

export default DataAbsenHarian;