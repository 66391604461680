import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import './css/datagaji.css';
import ResizeObserver from 'resize-observer-polyfill';  // Import ResizeObserver

axios.defaults.withCredentials = true;

const getApiBaseUrl = () => {
  const protocol = window.location.protocol === 'https:' ? 'https' : 'http';
 const baseUrl = process.env.REACT_APP_API_BASE_URL.replace(/^https?:\/\//, '');
  return `${protocol}://${baseUrl}`;
};

const fetcher = (url) => axios.get(url).then(res => res.data);

// Fungsi untuk memformat angka menjadi format Rupiah
const formatRupiah = (angka) => {
  if (angka == null) return 'Rp0';
  return 'Rp' + angka.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const Datagaji = () => {
  const [selectedPeriode, setSelectedPeriode] = useState('');
  const { data: gaji, error, mutate } = useSWR(
    `${getApiBaseUrl()}/getgajiall`, 
    fetcher
  );

  const scrollContainerRef = useRef(null); // Create ref for the scroll-container

  // Use ResizeObserver to observe size changes
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        console.log('Container size changed:', entry.contentRect); // You can handle resize logic here
      }
    });

    if (scrollContainerRef.current) {
      resizeObserver.observe(scrollContainerRef.current); // Observe the scroll-container
    }

    return () => {
      if (scrollContainerRef.current) {
        resizeObserver.unobserve(scrollContainerRef.current); // Cleanup observer on unmount
      }
    };
  }, []);

  if (error) return <div>Error loading data</div>;
  if (!gaji) return <div>Loading...</div>;

  // Filter and sort data based on selected periode
  const filteredGaji = selectedPeriode
    ? gaji.filter(gaji => gaji.periode === selectedPeriode)
    : gaji;

  const deletegaji = async (id) => {
    const userConfirm = window.confirm('Apakah anda ingin menghapus Slip gaji?');

    if (userConfirm) {
      try {
        await axios.delete(`${getApiBaseUrl()}/deletegaji/${id}`);
        console.log('Slip gaji berhasil dihapus');
        mutate(); 
      } catch (error) {
        console.error('Slip gaji gagal dihapus:', error);
      }
    }
  };

  return (
    <div className="card is-shadowless">
      <div className="scroll-container" ref={scrollContainerRef}> {/* Attach ref here */}
        <div className="filter-container mb-3">
          <label className="me-2">Filter Periode:</label>
          <select 
            value={selectedPeriode} 
            onChange={(e) => setSelectedPeriode(e.target.value)}
            className="form-select"
          >
            <option value="">Semua Periode</option>
            <option value="januari">Januari</option>
            <option value="februari">Februari</option>
            <option value="maret">Maret</option>
            <option value="april">April</option>
            <option value="mei">Mei</option>
            <option value="juni">Juni</option>
            <option value="juli">Juli</option>
            <option value="agustus">Agustus</option>
            <option value="september">September</option>
            <option value="oktober">Oktober</option>
            <option value="november">November</option>
            <option value="desember">Desember</option>
          </select>
        </div>
        <div className="scrollable-container">
          <table className="scrollable-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Nama Karyawan</th>
                <th>Cabang</th>
                <th>Tanggal Periode</th>
                <th>Periode</th>
                <th>Nominal</th>
                <th>Tambahan</th>
                <th>Potongan</th>
                <th>Jumlah</th>
                <th>Keterangan</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredGaji.map((gaji, index) => (
                <tr key={gaji.id}>
                  <td>{index + 1}</td>
                  <td>{gaji.Karyawan ? gaji.Karyawan.nama_lengkap : 'N/A'}</td>
                  <td>{gaji.Karyawan.Cabang ? gaji.Karyawan.Cabang.nama_cabang : 'N/A'}</td>
                  <td>{gaji.tanggal_periode}</td>
                  <td>{gaji.periode}</td>
                  <td>{formatRupiah(gaji.nominal)}</td> {/* Memformat nominal */}
                  <td>{formatRupiah(gaji.tambahan)}</td> {/* Memformat tambahan */}
                  <td>{formatRupiah(gaji.potongan)}</td> {/* Memformat potongan */}
                  <td>{formatRupiah(gaji.jumlah)}</td> {/* Memformat jumlah */}
                  <td>{gaji.keterangan}</td>
                  <td>{gaji.status}</td>
                  <td>
                    <Link to={`/editdatagaji/${gaji.id}`} className="button is-small is-info">
                      Edit
                    </Link>
                    <button onClick={() => deletegaji(gaji.id)} className="button is-small is-danger">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
