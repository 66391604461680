import React from 'react';
import axios from 'axios';
import useSWR from 'swr';
import { Link } from 'react-router-dom';

axios.defaults.withCredentials = true;

const getApiBaseUrl = () => {
  const protocol = window.location.protocol === 'https:' ? 'https' : 'http';
 const baseUrl = process.env.REACT_APP_API_BASE_URL.replace(/^https?:\/\//, '');
  return `${protocol}://${baseUrl}`;
};

const fetcher = (url) => axios.get(url).then(res => res.data);

const DataAdmin = () => {
    const { data: admin, error, mutate } = useSWR(`${getApiBaseUrl()}/users`, fetcher);

  if (error) return <div>Error loading data</div>;
  if (!admin) return <div>Loading...</div>;

  const deleteAdmin = async (id) =>{
    const userConfirm = window.confirm('Apakah anda yakin ingin hapus akun ini?')
    if(userConfirm){
    try {
        await axios.delete(`${getApiBaseUrl()}/users/${id}`);
        console.log('Akun Admin Berhasil di hapus');   
        mutate();  
    } catch (error) {
        console.error('Akun Admin Gagal Di Hapus')
    }
    }
  }


  return (
    <div className="card is-shadowless">
      <div className="table-container">
      <Link to="/admin/add" className="button is-primary mb-2">
        Add User
      </Link>
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>No</th>
              <th>Nama</th>
              <th>Email</th>
              <th>Role Kantor</th>
              {/* <th>Radius Absensi</th>*/}
              <th>Actions</th> 
            </tr>
          </thead>
          <tbody>
            {admin.map((users, index) => (
              <tr key={users.id}>
                <td>{index + 1}</td>
                <td>{users.name}</td>
                <td>{users.email}</td>
                <td>{users.role}</td>
                {/* <td>{users.radius} Meter</td> */}
                <td>
                  <Link to={`/dataadmin/edit/${users.id}`} className="button is-small is-info">
                    Edit
                  </Link>
                  <button onClick={() => deleteAdmin(users.id)} className="button is-small is-danger">
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default DataAdmin