import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import "../admin/css/dataKaryawan.css";
import { ButtonGroup, Modal, Button, Row, Col } from 'react-bootstrap';
import { FaInfoCircle, FaEdit, FaTrash } from 'react-icons/fa';

axios.defaults.withCredentials = true;

const getApiBaseUrl = () => {
  const protocol = window.location.protocol === 'https:' ? 'https' : 'http';
  const baseUrl = process.env.REACT_APP_API_BASE_URL.replace(/^https?:\/\//, '');
  return `${protocol}://${baseUrl}`;
};

const fetcher = (url) => axios.get(url).then(res => res.data);

export const DataKaryawan = () => {
  const { data: karyawan, error, mutate } = useSWR(`${getApiBaseUrl()}/karyawan`, fetcher);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedKaryawan, setSelectedKaryawan] = useState(null);

  if (error) return <div>Error loading data</div>;
  if (!karyawan) return <div>Loading...</div>;

  const karyawanList = Array.isArray(karyawan) ? karyawan : karyawan.karyawan;

  if (!Array.isArray(karyawanList)) {
    return <div>Data format is incorrect</div>;
  }

  const filteredKaryawan = karyawanList.filter((karyawan) =>
    karyawan.nama_lengkap?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const karyawanByCabang = filteredKaryawan.reduce((result, karyawan) => {
    const cabangName = karyawan.Cabang ? karyawan.Cabang.nama_cabang : 'N/A';
    if (!result[cabangName]) {
      result[cabangName] = [];
    }
    result[cabangName].push(karyawan);
    return result;
  }, {});

  const deleteKaryawan = async (id) => {
    const userConfirm = window.confirm('Apakah anda ingin menghapus data?');
    if (userConfirm) {
      try {
        console.log('Deleting karyawan with id:', id);
        await axios.delete(`${getApiBaseUrl()}/karyawan/${id}`);
        console.log('Data berhasil dihapus');
        mutate();
      } catch (error) {
        console.error('Data gagal dihapus:', error.response ? error.response.data : error.message);
      }
    }
  };

  const handleShowModal = (karyawan) => {
    setSelectedKaryawan(karyawan);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedKaryawan(null);
  };

  return (
    <div className="card is-shadowless">
      <div className="table-container">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Cari nama"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="input is-small search-input"
          />
        </div>
        {Object.keys(karyawanByCabang).map((cabangName, cabangIndex) => (
          <div key={cabangIndex} className="card is-shadowless">
            <p className>Cabang: {cabangName}</p>
            <table className="table is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Username</th>
                  <th>Nama</th>
                  <th>Jabatan</th>
                  <th>Cabang</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {karyawanByCabang[cabangName].map((karyawan, index) => (
                  <tr key={karyawan.id || index}>
                    <td>{index + 1}</td>
                    <td>{karyawan.username}</td>
                    <td>{karyawan.nama_lengkap}</td>
                    <td>{karyawan.jabatan}</td>
                    <td>{cabangName}</td>
                    <td>
                    <ButtonGroup aria-label="Actions">
    <Button 
      className="is-small is-info" 
      onClick={() => handleShowModal(karyawan)}
    >
      <FaInfoCircle /> 
    </Button>
    <Link to={`/datakaryawan/edit/${karyawan.id}`} className="btn btn-info btn-sm">
      <FaEdit /> 
    </Link>
    <Button 
      className="is-small is-danger" 
      onClick={() => deleteKaryawan(karyawan.id)}
    >
      <FaTrash /> 
    </Button>
  </ButtonGroup>
</td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}

        {/* Modal untuk menampilkan detail karyawan */}
        {/* <Modal show={showModal} onHide={handleCloseModal}> */}
        <Modal 
  show={showModal} 
  onHide={handleCloseModal} 
  centered
  backdrop="static" // Menjaga backdrop modal agar tidak tertutup
  keyboard={false} // Disable close modal on keyboard events
  style={{ zIndex: 1050 }} // Meningkatkan z-index modal agar di atas elemen lain
>
          <Modal.Header closeButton>
            <Modal.Title>Detail Karyawan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedKaryawan && (
              <div>
                <Row>
                  <Col><strong>Nama Lengkap:</strong></Col>
                  <Col>{selectedKaryawan.nama_lengkap}</Col>
                </Row>
                <Row>
                  <Col><strong>Username:</strong></Col>
                  <Col>{selectedKaryawan.username}</Col>
                </Row>
                <Row>
                  <Col><strong>Jabatan:</strong></Col>
                  <Col>{selectedKaryawan.jabatan}</Col>
                </Row>
                <Row>
                  <Col><strong>Cabang:</strong></Col>
                  <Col>{selectedKaryawan.Cabang?.nama_cabang || 'N/A'}</Col>
                </Row>
                <Row>
                  <Col><strong>Telepon:</strong></Col>
                  <Col>{selectedKaryawan.no_telp}</Col>
                </Row>
                <Row>
                  <Col><strong>Foto:</strong></Col>
                  <Col>
                  <img src={`${getApiBaseUrl()}/uploads/karyawan/${selectedKaryawan.avatar}`} alt="Avatar" style={{ width: '100px', height: '100px' }} />
                  </Col>
                </Row>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Tutup
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
