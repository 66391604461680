import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import jsPDF from 'jspdf';
import Swal from 'sweetalert2';
import useSWR, { mutate } from 'swr'; // Import mutate

import { Container, Card, CardContent, Typography, Grid, Button, Modal, Box, CircularProgress, Alert } from '@mui/material';
const getApiBaseUrl = () => {
    const protocol = window.location.protocol === 'https:' ? 'https' : 'http';
   const baseUrl = process.env.REACT_APP_API_BASE_URL.replace(/^https?:\/\//, '');
    return `${protocol}://${baseUrl}`;
  };
// Fungsi fetcher untuk useSWR
const fetcher = (url) => axios.get(url, { withCredentials: true }).then((res) => res.data);

export const ConfirmGaji = () => {
    const navigate = useNavigate();
    const [selectedGaji, setSelectedGaji] = useState(null);
    const [open, setOpen] = useState(false); // Untuk modal

    // Menggunakan useSWR untuk fetch data
    const { data: gajiList, error, isLoading } = useSWR(
        `${getApiBaseUrl()}/getgaji`, 
        fetcher,
        {
            onSuccess: (data) => data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        }
    );

    const handleOpenModal = async (id) => {
        try {
            const response = await axios.get(`${getApiBaseUrl()}/getgajibyid/${id}`, { withCredentials: true });
            setSelectedGaji(response.data);
            setOpen(true); // Buka modal
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error("Unauthorized access. Redirecting to login...");
                navigate('/login'); // Redirect ke halaman login jika 401
            } else {
                console.error("Gagal mengambil detail slip gaji:", error);
                Swal.fire({
                    title: 'Error!',
                    text: 'Gagal mengambil detail slip gaji. Silakan coba lagi nanti.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        }
    };

    const handleCloseModal = () => setOpen(false);

    const confirmGaji = async () => {
        if (!selectedGaji) return;

        setOpen(false); // Tutup modal sebelum SweetAlert muncul

        try {
            const result = await Swal.fire({
                title: 'Konfirmasi Terima Gaji',
                text: 'Apakah Anda yakin ingin menerima slip gaji ini?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya, Terima',
                cancelButtonText: 'Batal',
                position: 'top'
            });

            if (result.isConfirmed) {
                await axios.put(`${getApiBaseUrl()}/updategajibykaryawan/${selectedGaji.id}`, {
                    status: 'diterima'
                }, { withCredentials: true });

                Swal.fire({
                    title: 'Berhasil!',
                    text: 'Slip gaji telah diterima.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    position: 'top'
                });

                // Mutate data untuk memperbarui cache secara otomatis
                mutate(`${getApiBaseUrl()}/getgaji`);
            }
        } catch (error) {
            console.error("Gagal memperbarui status gaji:", error);
            Swal.fire({
                title: 'Gagal!',
                text: 'Gagal memperbarui status gaji. Silakan coba lagi nanti.',
                icon: 'error',
                confirmButtonText: 'OK',
                position: 'top'
            });
        }
    };

    const downloadPDF = () => {
        if (!selectedGaji) return;
    
        const doc = new jsPDF();
        const rightAlignX = 160; // Posisi X untuk data di sebelah kanan
        const leftAlignX = 10;   // Posisi X untuk label di sebelah kiri
    
        // Header Slip Gaji PT. BR Solusindo
        doc.setFontSize(16);
        doc.text('Slip Gaji PT. BR Solusindo', 105, 20, null, null, 'center'); // Header Centered
        doc.setFontSize(12);
        doc.text('==========================================', 105, 25, null, null, 'center'); // Garis pemisah (lebih dekat ke header)
    
        // Detail Slip Gaji
        doc.setFontSize(12);
        doc.text('Detail Slip Gaji', leftAlignX, 40);
    
        const formattedDate = new Date(selectedGaji.tanggal_periode).toLocaleDateString('id-ID', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    
        // Teks dan data sejajar
        doc.text('Nama:', leftAlignX, 50);
        doc.text(selectedGaji.Karyawan?.nama_lengkap || 'Data tidak tersedia', rightAlignX, 50, null, null, 'right');
    
        doc.text('Cabang:', leftAlignX, 60);
        doc.text(selectedGaji.Karyawan?.Cabang?.nama_cabang || 'Data tidak tersedia', rightAlignX, 60, null, null, 'right');
    
        doc.text('Tanggal:', leftAlignX, 70);
        doc.text(formattedDate, rightAlignX, 70, null, null, 'right');
    
        doc.text('Nominal:', leftAlignX, 80);
        doc.text(formatRupiah(selectedGaji.nominal), rightAlignX, 80, null, null, 'right');
    
        doc.text('Tambahan:', leftAlignX, 90);
        doc.text(formatRupiah(selectedGaji.tambahan), rightAlignX, 90, null, null, 'right');
    
        doc.text('Potongan:', leftAlignX, 100);
        doc.text(formatRupiah(selectedGaji.potongan), rightAlignX, 100, null, null, 'right');
    
        doc.text('Jumlah:', leftAlignX, 110);
        doc.text(formatRupiah(selectedGaji.jumlah), rightAlignX, 110, null, null, 'right');
    
        doc.text('Status:', leftAlignX, 120);
        doc.text(selectedGaji.status, rightAlignX, 120, null, null, 'right');
    
        // Footer
        doc.text('==========================================', 105, 130, null, null, 'center'); // Garis pemisah footer (lebih dekat ke teks)
        doc.text('Terima Kasih atas kerja keras Anda!', 105, 135, null, null, 'center'); // Teks footer dengan jarak yang lebih rapi
    
        // Save the PDF
        doc.save(`Slip_Gaji_PT_BRSolusindo_${selectedGaji.Karyawan?.nama_lengkap || 'Data'}_${formattedDate}.pdf`);
    };
    
    

    const formatRupiah = (number) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0
        }).format(number);
    };

    if (isLoading) {
        return <CircularProgress />; // Menampilkan indikator loading saat data sedang diambil
    }

    if (error) {
        return <Alert severity="error">Gagal mengambil data. Silakan coba lagi nanti.</Alert>; // Menampilkan pesan kesalahan
    }

    return (
        <Container>
            <Typography variant="p" gutterBottom>Daftar Slip Gaji</Typography>
            {gajiList?.length > 0 ? (
                gajiList.map((gaji) => (
                    <Card key={gaji.id} style={{ marginBottom: '20px' }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Nama:</strong>
                                        <span>{gaji.Karyawan?.nama_lengkap || 'Data tidak tersedia'}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Periode:</strong>
                                        <span>{gaji.periode}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Tanggal:</strong>
                                        <span>{new Date(gaji.tanggal_periode).toLocaleDateString('id-ID', { day: '2-digit', month: '2-digit', year: 'numeric' })}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Jumlah:</strong>
                                        <span>{formatRupiah(gaji.jumlah)}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Status:</strong>
                                        <span>{gaji.status}</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleOpenModal(gaji.id)}
                                style={{ padding: '6px 12px', fontSize: '0.875rem' }}
                            >
                                Lihat Detail & Konfirmasi
                            </Button>
                        </CardContent>
                    </Card>
                ))
            ) : (
                <Typography>Data gaji tidak ditemukan.</Typography>
            )}

            <Modal open={open} onClose={handleCloseModal}>
                <Box style={{ backgroundColor: 'white', padding: '20px', margin: 'auto', marginTop: '10%', maxWidth: '500px' }}>
                    {selectedGaji ? (
                        <>
                            <Grid container spacing={2}>
                            <Grid item xs={12}>
                                    <Typography variant="h5" style={{ marginBottom: '20px' }}>Detail Slip Gaji</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Nama:</strong>
                                        <span>{selectedGaji.Karyawan?.nama_lengkap || 'Data tidak tersedia'}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Periode:</strong>
                                        <span>{selectedGaji.periode}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Tanggal:</strong>
                                        <span>{selectedGaji.tanggal_periode}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Cabang:</strong>
                                        <span>{selectedGaji.Karyawan?.Cabang?.nama_cabang || 'Data tidak tersedia'}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Nominal:</strong>
                                        {/* <span>{selectedGaji.nominal}</span> */}
                                        <span>{formatRupiah(selectedGaji.nominal)}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Tambahan:</strong>
                                        {/* <span>{selectedGaji.tambahan}</span> */}
                                        <span>{formatRupiah(selectedGaji.tambahan)}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Potongan:</strong>
                                        {/* <span>{selectedGaji.potongan}</span> */}
                                        <span>{formatRupiah(selectedGaji.potongan)}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Total:</strong>
                                        {/* <span>{selectedGaji.jumlah}</span> */}
                                        <span>{formatRupiah(selectedGaji.jumlah)}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <strong>Status:</strong>
                                        <span>{selectedGaji.status}</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Button variant="contained" color="primary" onClick={confirmGaji}>
                                Terima Gaji
                            </Button>
                            <Button variant="contained" color="secondary" onClick={downloadPDF} style={{ marginLeft: '10px' }}>
                                Download PDF
                            </Button>
                        </>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>
            </Modal>
            <Button component={NavLink} to="/dashboard" variant="outlined" fullWidth>
                Kembali
            </Button>
        </Container>
    );
};
