import React, { useState, useRef } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import jsPDF from 'jspdf';
import "jspdf-autotable"; // Untuk tabel di PDF
import "../admin/css/data.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import {IoSaveOutline,IoArchive } from "react-icons/io5";

axios.defaults.withCredentials = true;

const getApiBaseUrl = () => {
    const protocol = window.location.protocol === 'https:' ? 'https' : 'http';
   const baseUrl = process.env.REACT_APP_API_BASE_URL.replace(/^https?:\/\//, '');
    return `${protocol}://${baseUrl}`;
  };

const fetcher = async (url) => {
    const response = await axios.get(url);
    if (response.status !== 200) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
    }
    return response.data;
};

const DataBulan = () => {
    const [bulan, setBulan] = useState('');
    const [tahun, setTahun] = useState('');
    const [fetchData, setFetchData] = useState(false);
    const [warning, setWarning] = useState('');
    const scrollRef = useRef(null);

    const { data: absensi, error } = useSWR(
        fetchData ? `${getApiBaseUrl()}/absensicabang/get?bulan=${bulan}&tahun=${tahun}` : null,
        fetcher
    );

    const extractDates = (data) => {
        const dateSet = new Set();
        Object.keys(data).forEach((key) => {
            data[key].absensi.forEach((absensi) => {
                dateSet.add(absensi.tgl_absensi);
            });
        });
        return Array.from(dateSet).sort();
    };

    const dates = absensi ? extractDates(absensi) : [];

    const sortedCabangKeys = absensi ? Object.keys(absensi).sort((a, b) => {
        const cabangA = absensi[a].cabang.nama_cabang.toLowerCase();
        const cabangB = absensi[b].cabang.nama_cabang.toLowerCase();
        return cabangA.localeCompare(cabangB);
    }) : [];

    const handleMonthChange = (e) => {
        const [year, month] = e.target.value.split("-");
        setBulan(month);
        setTahun(year);
    };

    const handleTampilkanData = () => {
        if (!bulan || !tahun) {
            setWarning('Silahkan pilih bulan dan tahun.');
        } else {
            setWarning(''); 
            setFetchData(true);
        }
    };

    const handleExportPDF = (cabangId) => {
        const cabang = absensi[cabangId];
        const doc = new jsPDF({ orientation: 'landscape' }); // Orientasi landscape untuk lebih banyak kolom
        const chunkSize = 5; // Jumlah kolom per grup tanggal
    
        // Judul di bagian atas
        doc.text(`Absensi PT.BR Solusindo ${cabang.cabang.nama_cabang}`, 14, 10);
    
        // Posisi awal untuk tabel pertama
        let startYPosition = 20;
    
        // Membuat tabel dalam chunk
        for (let i = 0; i < dates.length; i += chunkSize) {
            const dateChunk = dates.slice(i, i + chunkSize);
    
            // Header baris pertama (tanggal)
            const headerRow1 = ["Nama", ...dateChunk.map(date => ({
                content: new Date(date).toLocaleDateString('id-ID'),
                colSpan: 2, // Membentang dua kolom (Jam Masuk dan Jam Keluar)
                styles: { halign: 'center' } // Menempatkan teks di tengah
            }))];
    
            // Header baris kedua (Jam Masuk dan Jam Keluar di bawah tanggal)
            const headerRow2 = ["", ...dateChunk.flatMap(() => ["Jam Masuk", "Jam Keluar"])];
    
            // Menyusun baris tabel berdasarkan data absensi
            const tableRows = [];
    
            cabang.absensi.reduce((acc, record) => {
                const existingRow = acc.find(row => row.nama === record.karyawan.nama_lengkap);
                if (existingRow) {
                    existingRow.data[record.tgl_absensi] = {
                        jamMasuk: record.jam_masuk || 'Libur',
                        jamKeluar: record.jam_keluar || ''
                    };
                } else {
                    acc.push({
                        nama: record.karyawan.nama_lengkap,
                        data: {
                            [record.tgl_absensi]: {
                                jamMasuk: record.jam_masuk || 'Libur',
                                jamKeluar: record.jam_keluar || ''
                            }
                        }
                    });
                }
                return acc;
            }, []).forEach(row => {
                const rowData = [row.nama];
                dateChunk.forEach(date => {
                    rowData.push(row.data[date]?.jamMasuk || 'Libur', row.data[date]?.jamKeluar || '');
                });
                tableRows.push(rowData);
            });
    
            // Cek jika butuh halaman baru
            if (startYPosition + 10 > doc.internal.pageSize.height - 30) {
                doc.addPage();
                startYPosition = 20; // Reset Y ke awal halaman
            }
    
            // Menghasilkan tabel di PDF dengan header multi-row
            doc.autoTable({
                head: [headerRow1, headerRow2], // Header multi-baris
                body: tableRows,
                startY: startYPosition,
                theme: 'grid',
                styles: {
                    fontSize: 12
                },
                headStyles: { 
                    fillColor: [173, 216, 230], // Ganti dengan warna RGB sesuai kebutuhan
                    textColor: [0, 0, 0], // Warna teks hitam (atau sesuaikan jika ingin warna lain)
                    halign: 'center', // Memastikan teks berada di tengah
                    valign: 'middle', // Menempatkan teks vertikal di tengah
                }
            });
    
            // Update posisi Y untuk tabel berikutnya
            startYPosition = doc.previousAutoTable.finalY + 10; // Spacer 10 unit antar tabel
        }
    
        // Lebar halaman
        const pageWidth = doc.internal.pageSize.getWidth();
    
        // Tambahkan teks untuk tanggal dan tanda tangan setelah semua data
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString('id-ID', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        });
    
        // Cek jika butuh halaman baru untuk tanda tangan
        if (startYPosition + 50 > doc.internal.pageSize.height - 30) {
            doc.addPage();
            startYPosition = 20; // Reset Y ke awal halaman
        }
    
        // Menambahkan teks "Semarang, tanggal - bulan - tahun" di pojok kanan
        startYPosition += 20; // Spacer sebelum teks tanda tangan
        doc.text(`Semarang, ${formattedDate}`, pageWidth - 100, startYPosition); // Adjust X value for right alignment
    
        // Menambahkan spasi kosong untuk tanda tangan
        startYPosition += 25; // Spacer untuk jarak tanda tangan
        doc.text('_____________', pageWidth - 80, startYPosition); // Garis untuk tanda tangan
        doc.text('     Wahono', pageWidth - 80, startYPosition + 10); // Nama pemilik di bawah garis tanda tangan
    
        // Menyimpan file PDF
        doc.save(`export-${cabang.cabang.nama_cabang}.pdf`);
    };
    
    
    
    const handleExportExcel = async (cabangId) => {
        try {
            const response = await axios.post(
                `${getApiBaseUrl()}/export/excel`,
                { data: absensi[cabangId] },
                { responseType: 'blob' }
            );
            
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `export-${absensi[cabangId].cabang.nama_cabang}.xlsx`);
            document.body.appendChild(link);
            link.click();
            
        } catch (error) {
            console.error('Error exporting Excel:', error);
        }
    };


    return (
        <div className="card is-shadowless">
            <form onSubmit={(e) => e.preventDefault()}>
                <label>
                    Bulan:
                    <input 
                        type="month" 
                        value={`${tahun}-${bulan}`} 
                        onChange={handleMonthChange} 
                        placeholder="YYYY-MM"
                    />
                </label>
                <button type="button" onClick={handleTampilkanData}>
                    Tampilkan Data
                </button>
            </form>
    
            {warning && <div style={{ color: 'red', marginTop: '10px' }}>{warning}</div>}
    
            {fetchData && error && error.response?.status === 404 && (
                <div>Data kosong untuk bulan dan tahun yang dipilih.</div>
            )}
    
            {sortedCabangKeys.length > 0 && sortedCabangKeys.map((key) => {
                const cabang = absensi[key];
                return (
                    <div key={key} className="card is-shadowless">
                        <h3>{cabang.cabang.nama_cabang}</h3>
                        <div className="scroll-container">
                            <div className="scrollable-container" ref={scrollRef}>
                                <table className="scrollable-table">
                                    <thead>
                                        <tr>
                                            <th rowSpan="2">Nama</th>
                                            {dates.map(date => (
                                                <th key={date} colSpan="2">{new Date(date).toLocaleDateString('id-ID')}</th>
                                            ))}
                                        </tr>
                                        <tr>
                                            {dates.map(date => (
                                                <React.Fragment key={date}>
                                                    <th>Jam Masuk</th>
                                                    <th>Jam Keluar</th>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cabang.absensi.reduce((acc, record) => {
                                            const existingRow = acc.find(row => row.nama === record.karyawan.nama_lengkap);
                                            if (existingRow) {
                                                existingRow.data[record.tgl_absensi] = {
                                                    jamMasuk: record.jam_masuk,
                                                    jamKeluar: record.jam_keluar
                                                };
                                            } else {
                                                acc.push({
                                                    nama: record.karyawan.nama_lengkap,
                                                    data: {
                                                        [record.tgl_absensi]: {
                                                            jamMasuk: record.jam_masuk,
                                                            jamKeluar: record.jam_keluar
                                                        }
                                                    }
                                                });
                                            }
                                            return acc;
                                        }, []).map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.nama}</td>
                                                {dates.map(date => (
                                                    <React.Fragment key={date}>
                                                        <td>{row.data[date]?.jamMasuk || ''}</td>
                                                        <td>{row.data[date]?.jamKeluar || ''}</td>
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12 col-md-6 mb-2">
                        <button 
                            className="btn btn-primary btn-block" 
                            button onClick={() => handleExportExcel(key)}
                        >
                            <IoSaveOutline /> Export to Excel
                        </button>
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <button 
                            className="btn btn-danger btn-block" 
                            onClick={() =>handleExportPDF(key)}
                        >
                            <IoArchive /> Export to PDF
                        </button>
                    </div>
                        </div>
                        
                    </div>
                    
                );
            })}
        </div>
    );
};

export default DataBulan;
