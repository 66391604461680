import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, NavLink } from 'react-router-dom';
import { LoginUser, reset } from "../fitur/AuthKaryawan";
import useSWR from 'swr';
import axios from 'axios';
import "./Login.css";
import background from "../img/backgound.png";

const getApiBaseUrl = () => {
  const protocol = window.location.protocol === 'https:' ? 'https' : 'http';
  const baseUrl = process.env.REACT_APP_API_BASE_URL.replace(/^https?:\/\//, '');
  return `${protocol}://${baseUrl}`;
};

const fetcher = url => axios.get(url).then(res => res.data);

const LoginKaryawan = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.authKaryawan
  );

  // Ping server menggunakan SWR hanya sekali
  const { data, error } = useSWR(`${getApiBaseUrl()}/`, fetcher);

  useEffect(() => {
    const savedUsername = localStorage.getItem('username');
    const savedPassword = localStorage.getItem('password');
    if (savedUsername && savedPassword) {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    if (user || isSuccess) {
      navigate("/dashboard");
    }
    dispatch(reset());
  }, [user, isSuccess, dispatch, navigate]);

  const Auth = (e) => {
    e.preventDefault();
    if (rememberMe) {
      localStorage.setItem('username', username);
      localStorage.setItem('password', password);
    } else {
      localStorage.removeItem('username');
      localStorage.removeItem('password');
    }
    dispatch(LoginUser({ username, password }));
  };

  const gambar = background;

  return (
    <div className="login-container">
      <div className="login-left">
        <img src={gambar} alt="Login Illustration" className="login-illustration" />
      </div>
      <div className="login-right">
        <form onSubmit={Auth} className="login-box">
          <p>Welcome user 👋</p>
          <p>Tunggu server online dan silahkan login</p>
          {isError && <p className="error-message">{message}</p>}
          
          {/* Tampilkan status server */}
          {error ? (
            <p className="error-message">Server Offline</p>
          ) : data ? (
            <p className="success-message">Server Online: {data}</p> // data akan menampilkan 'berhasil'
          ) : (
            <p className="loading-message">Checking server status...</p>
          )}

          <div className="field">
            <label className="label">Username</label>
            <div className="control">
              <input
                type="text"
                className="input"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter your Username"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Password</label>
            <div className="control">
              <input
                type="password"
                className="input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
            </div>
          </div>
          <div className="field">
            <label className="checkbox">
              <input 
                type="checkbox" 
                checked={rememberMe} 
                onChange={(e) => setRememberMe(e.target.checked)} 
              /> Remember me
            </label>
          </div>
          <div className="field">
            <button type="submit" className="button is-primary is-fullwidth">
              {isLoading ? "Loading..." : "Login"}
            </button>
          </div>
          <div className="field">
            <NavLink to="/loginadmin" className="button is-secondary is-halfwidth">
              Go to Admin Login
            </NavLink>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginKaryawan;
