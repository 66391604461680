import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import { MapContainer, TileLayer, Circle, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './css/datacabang.css';

// Set default icon for leaflet markers
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

axios.defaults.withCredentials = true;

const getApiBaseUrl = () => {
  const protocol = window.location.protocol === 'https:' ? 'https' : 'http';
  const baseUrl = process.env.REACT_APP_API_BASE_URL.replace(/^https?:\/\//, '');
  return `${protocol}://${baseUrl}`;
};

const fetcher = (url) => axios.get(url).then(res => res.data);

export const Datacabang = () => {
  const { data: cabang, error, mutate } = useSWR(`${getApiBaseUrl()}/cabang`, fetcher);
  const [selectedCabang, setSelectedCabang] = useState(null); // State untuk cabang yang dipilih
  const [showModal, setShowModal] = useState(false); // State untuk kontrol modal

  if (error) return <div>Error loading data</div>;
  if (!cabang) return <div>Loading...</div>;

  const deleteCabang = async (id) => {
    const userConfirm = window.confirm('Apakah anda ingin menghapus cabang?');

    if (userConfirm) {
      try {
        await axios.delete(`${getApiBaseUrl()}/cabang/${id}`);
        console.log('Cabang berhasil dihapus');
        mutate(); 
      } catch (error) {
        console.error('Cabang gagal dihapus:', error);
      }
    }
  };

  // Function untuk membuka modal dengan data cabang yang dipilih
  const handleShowModal = (cabang) => {
    const [latitude, longitude] = cabang.lokasi_kantor.split(',').map(coord => parseFloat(coord.trim())); // Pisahkan lokasi_kantor
    
    if (latitude && longitude) {
      setSelectedCabang({ ...cabang, latitude, longitude }); // Set latitude dan longitude ke state selectedCabang
      setShowModal(true);
    } else {
      alert('Lokasi tidak tersedia untuk cabang ini.');
    }
  };

  // Function untuk menutup modal
  const handleCloseModal = () => {
    setSelectedCabang(null);
    setShowModal(false);
  };
  if (error) return <div>Error loading data</div>;
if (!cabang) return <div>Loading...</div>;
if (!Array.isArray(cabang)) return <div>Data tidak valid</div>;

  return (
    <div className="card is-shadowless">
      <div className="table-container">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>No</th>
              <th>Nama Cabang</th>
              <th>Kode Cabang</th>
              <th>Lokasi Kantor</th>
              <th>Radius Absensi</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {cabang.map((cabang, index) => (
              <tr key={cabang.id}>
                <td>{index + 1}</td>
                <td>{cabang.nama_cabang}</td>
                <td>{cabang.kode_cabang}</td>
                <td>{cabang.lokasi_kantor}</td>
                <td>{cabang.radius} Meter</td>
                <td>
                    {/* Button untuk membuka modal */}
                    <button onClick={() => handleShowModal(cabang)} className="button is-small is-primary">
                    Lihat Lokasi
                  </button>
                  <Link to={`/datacabang/edit/${cabang.id}`} className="button is-small is-info">
                    Edit
                  </Link>
                  <button onClick={() => deleteCabang(cabang.id)} className="button is-small is-danger">
                    Delete
                  </button>
                
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal untuk menampilkan peta */}
     {/* Modal untuk menampilkan peta */}
     {/* <Modal show={showModal} onHide={handleCloseModal} backdrop="static" scrollable> */}
     <Modal show={showModal} 
  onHide={handleCloseModal}
  centered
  backdrop="static" 
  keyboard={false} 
  style={{ zIndex: 1050 }}> 
 <Modal.Header closeButton>
    <Modal.Title>Detail Lokasi Cabang</Modal.Title>
  </Modal.Header>
  <Modal.Body 
    style={{ 
      maxHeight: '500px',  // Set a maximum height for the modal body
      overflowY: 'auto',   // Enable vertical scrolling if content exceeds height
    }}
  >
    {selectedCabang && (
      <>
        <div>
          <p><strong>Nama Cabang:</strong> {selectedCabang.nama_cabang}</p>
          <p><strong>Kode Cabang:</strong> {selectedCabang.kode_cabang}</p>
          <p><strong>Lokasi Kantor:</strong> {selectedCabang.lokasi_kantor}</p>
          <p><strong>Radius Absensi:</strong> {selectedCabang.radius} Meter</p>
        </div>
        
        <div style={{ marginTop: '20px' }}>
          <p><strong>Lokasi pada Peta:</strong></p>
          <div style={{ width: '100%', height: '400px', borderRadius: '5px', marginBottom: '10px' }}>
            {selectedCabang.latitude && selectedCabang.longitude ? (
              <MapContainer
                center={[selectedCabang.latitude, selectedCabang.longitude]}
                zoom={15}
                style={{ height: '100%', width: '100%' }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={[selectedCabang.latitude, selectedCabang.longitude]}>
                  <Popup>{selectedCabang.nama_cabang}</Popup>
                </Marker>
                <Circle
                  center={[selectedCabang.latitude, selectedCabang.longitude]}
                  radius={selectedCabang.radius}
                  color="blue"
                />
              </MapContainer>
            ) : (
              <p>Lokasi tidak tersedia</p>
            )}
          </div>
        </div>
      </>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseModal}>
      Tutup
    </Button>
  </Modal.Footer>
</Modal>


    </div>
  );
};
