import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const getApiBaseUrl = () => {
    const protocol = window.location.protocol === 'https:' ? 'https' : 'http';
   const baseUrl = process.env.REACT_APP_API_BASE_URL.replace(/^https?:\/\//, '');
    return `${protocol}://${baseUrl}`;
  };

export const AbsenManual = () => {
    const [tgl_absensi, setTanggalAbsensi] = useState('');
    const [jam_masuk, setJamMasuk] = useState('');
    const [jam_keluar, setJamKeluar] = useState('');
    const [karyawans, setKaryawans] = useState([]);
    const [karyawanId, setKaryawan] = useState(''); // Tambahkan state untuk karyawanId
    const [searchTerm, setSearchTerm] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${getApiBaseUrl()}/karyawan`);
                if (Array.isArray(response.data.karyawan)) {
                    setKaryawans(response.data.karyawan);
                } else {
                    setKaryawans([]);
                }
            } catch (error) {
                setError('Gagal mengambil data karyawan.');
            }
        };
        fetchData();
    }, []);

    const saveAbsen = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${getApiBaseUrl()}/absensi/manual`, {
                karyawanId, // Kirim karyawanId yang dipilih
                jam_masuk,
                jam_keluar,
                tanggal: tgl_absensi
            });
            console.log(response)
            setMessage('Absensi berhasil dibuat');
            navigate('/data');
        } catch (error) {
            setError(error.response ? error.response.data.msg : 'Gagal menambah absensi.');
        }
    };

    const filteredKaryawans = karyawans.filter(karyawan =>
        karyawan.nama_lengkap.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (karyawan.Cabang && karyawan.Cabang.nama_cabang.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    return (
        <div className="card is-shadowless">
            <div className="card-content">
                <div className="content">
                    <form onSubmit={saveAbsen}>
                        <p className="has-text-centered">{message}</p>
                        {error && <p className="has-text-centered has-text-danger">{error}</p>} {/* Tampilkan pesan error */}
                        <div className="field">
                            <label className="label">Pilih Karyawan</label>
                            <div className="control">
                                <input
                                    type="text"
                                    placeholder="Cari Karyawan..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="input mb-2"
                                />
                                <div className="select is-fullwidth">
                                    <select value={karyawanId} onChange={(e) => setKaryawan(e.target.value)} required>
                                        <option value="">Pilih Karyawan</option>
                                        {filteredKaryawans.map((karyawan) => (
                                            <option key={karyawan.id} value={karyawan.id}>
                                                {`${karyawan.nama_lengkap} - ${karyawan.Cabang ? karyawan.Cabang.nama_cabang : 'Cabang Tidak Tersedia'}`}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Jam Masuk</label>
                            <div className="control">
                                <input
                                    type="time"
                                    className="input"
                                    value={jam_masuk}
                                    onChange={(e) => setJamMasuk(e.target.value)}
                                    placeholder="Silahkan ganti Jam Masuk"
                                />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Jam Keluar</label>
                            <div className="control">
                                <input
                                    type="time"
                                    className="input"
                                    value={jam_keluar}
                                    onChange={(e) => setJamKeluar(e.target.value)}
                                    placeholder="Edit Jam Keluar"
                                />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Tanggal Absensi</label>
                            <div className="control">
                                <input
                                    type="date"
                                    className="input"
                                    value={tgl_absensi}
                                    onChange={(e) => setTanggalAbsensi(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="field">
                            <div className="control">
                                <button type="submit" className="button is-success">
                                    Tambah Absensi
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
