import React from 'react';
import useSWR from 'swr';
import axios from 'axios';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import { FaUsers, FaBuilding, FaCalendarAlt } from 'react-icons/fa';
import AbsensiChart from './DataAbsensi';
import DataAbsenHarian from './DataAbsenHarian';
import "../admin/css/dashboardAdmin.css";

const getApiBaseUrl = () => {
  const protocol = window.location.protocol === 'https:' ? 'https' : 'http';
  const baseUrl = process.env.REACT_APP_API_BASE_URL.replace(/^https?:\/\//, '');
  return `${protocol}://${baseUrl}`;
};

const fetcher = url => axios.get(url, { withCredentials: true }).then(res => res.data);

const DashboardAdmin = () => {
  const { data: karyawanData, error: karyawanError } = useSWR(`${getApiBaseUrl()}/karyawan`, fetcher);
  const { data: cabangData, error: cabangError } = useSWR(`${getApiBaseUrl()}/cabang`, fetcher);
  const { data: absensiHariIniData, error: absensiHariIniError } = useSWR(`${getApiBaseUrl()}/absensihari/get`, fetcher);

  const totalKaryawan = karyawanData?.totalKaryawan || 0;
  const totalCabang = cabangData?.length || 0;
  const totalAbsenHariIni = absensiHariIniData?.jumlahAbsensi || 0;

  if (karyawanError || cabangError || absensiHariIniError) {
    console.error('Error fetching data:', karyawanError || cabangError || absensiHariIniError);
    return <div>Error loading data...</div>;
  }

  const cardData = [
    {
      title: 'Total Karyawan',
      value: totalKaryawan,
      color: '#ff9800',
      icon: <FaUsers size={50} />,
    },
    {
      title: 'Total Cabang',
      value: totalCabang,
      color: '#4caf50',
      icon: <FaBuilding size={50} />,
    },
    {
      title: 'Total Kehadiran Hari Ini',
      value: totalAbsenHariIni,
      color: '#3f51b5',
      icon: <FaCalendarAlt size={50} />,
    },
  ];

  return (
    <div className="dashboard-admin">
      <Grid container spacing={3}>
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                borderRadius: '15px',
                padding: '20px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '150px',
                background: card.color,
                color: 'white',
              }}
            >
              <CardContent>
                <Typography variant="subtitle1">{card.title}</Typography>
                <Typography variant="h4">{card.value}</Typography>
              </CardContent>
              <div>{card.icon}</div>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Chart Sections */}
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Data Absensi Bulan Ini
              </Typography>
              <AbsensiChart />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Data Kehadiran Hari Ini
              </Typography>
              <DataAbsenHarian />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardAdmin;
