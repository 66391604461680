import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const getApiBaseUrl = () => {
  const protocol = window.location.protocol === 'https:' ? 'https' : 'http';
 const baseUrl = process.env.REACT_APP_API_BASE_URL.replace(/^https?:\/\//, '');
  return `${protocol}://${baseUrl}`;
};

export const FormEditAbsensi = () => {
  const [jam_masuk, setJamMasuk] = useState('');
  const [jam_keluar, setJamKeluar] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getAbsensiAll = async () => {
      try {
        const response = await axios.get(`${getApiBaseUrl()}/absensiall/${id}`,{withCredentials: true});
        
        console.log("Respons API:", response.data);  // Debugging respons API
  
        // Mengisi state dengan data dari respons API jika ada, dan pastikan formatnya cocok dengan input type="time"
        if (response.data.jam_masuk) {
          const jamMasuk = response.data.jam_masuk.substring(0, 5); // Memotong ke format HH:mm
          setJamMasuk(jamMasuk);
        }
        if (response.data.jam_keluar) {
          const jamKeluar = response.data.jam_keluar.substring(0, 5); // Memotong ke format HH:mm
          setJamKeluar(jamKeluar);
        }
      } catch (error) {
        if (error.response) {
          setMessage(error.response.data.message);
        }
      }
    };
    getAbsensiAll();
  }, [id]);

  const updateAbsen = async (e) => {
    e.preventDefault();
    if (!jam_masuk || !jam_keluar) {
        setMessage('Jam Masuk dan Jam Keluar harus diisi');
        return;
      }
    try {
      await axios.put(`${getApiBaseUrl()}/absensi/${id}`, {
        jam_masuk,
        jam_keluar,
      },{withCredentials: true});
      navigate('/editdata');
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.message);
      }
    }
  };

  return (
    <div className="card is-shadowless">
      <div className="card-content">
        <div className="content">
          <form onSubmit={updateAbsen}>
            <p className="has-text-centered">{message}</p>

            <div className="field">
              <label className="label">Jam Masuk</label>
              <div className="control">
                <input
                  type="time"
                  className="input"
                  value={jam_masuk}
                  onChange={(e) => setJamMasuk(e.target.value)}
                  placeholder="Silahkan ganti Jam Masuk"
                />
              </div>
            </div>

            <div className="field">
              <label className="label">Jam Keluar</label>
              <div className="control">
                <input
                  type="time"
                  className="input"
                  value={jam_keluar}
                  onChange={(e) => setJamKeluar(e.target.value)}
                  placeholder="Edit Jam Keluar"
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <button type="submit" className="button is-success">
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
