import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
const getApiBaseUrl = () => {
  const protocol = window.location.protocol === 'https:' ? 'https' : 'http';
 const baseUrl = process.env.REACT_APP_API_BASE_URL.replace(/^https?:\/\//, '');
  return `${protocol}://${baseUrl}`;
};

export const CreateKaryawan = () => {
  const [username, setUsername] = useState('');
  const [namaLengkap, setNamaLengkap] = useState('');
  const [jabatan, setJabatan] = useState('');
  const [cabangId, setCabangId] = useState('');
  const [noTelp, setNoTelp] = useState('');
  const [password, setPassword] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [file, setFile] = useState("");
  const [cabangs, setCabangs] = useState([]);
  const [message, setMessage] = useState('');
  const [preview, setPreview] = useState("");
  const navigate = useNavigate();

  console.log(setAvatar)

  useEffect(() => {
    const fetchData = async () => {
      try {
         const responseCabang = await axios.get(`${getApiBaseUrl()}/cabang`,{withCredentials:true});
        setCabangs(responseCabang.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const saveKaryawan = async (e) => {
    e.preventDefault();
    if (!username || !namaLengkap || !jabatan || !cabangId || !noTelp || !password || !file) {
      Swal.fire({
        icon: 'warning',
        title: 'Form tidak lengkap!',
        text: 'Harap isi semua kolom yang wajib diisi.',
      });
      return;
    }
    try {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('nama_lengkap', namaLengkap);
        formData.append('jabatan', jabatan);
        formData.append('CabangId', cabangId); 
        formData.append('no_telp', noTelp);
        formData.append('password', password);
        formData.append('avatar', avatar);
        formData.append("file", file);

        const response = await axios.post(`${getApiBaseUrl()}/karyawan`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }, { withCredentials: true });

       // setMessage('Karyawan berhasil dibuat');
       Swal.fire({
        icon: 'success',
        title: 'Berhasil',
        text: 'Karyawan berhasil dibuat!',
      });
        navigate('/datakaryawan');
        console.log(response.data)
    } catch (error) {
      if (error.response && error.response.status === 409) {
        Swal.fire({
          icon: 'error',
          title: 'Username sudah terdaftar',
          text: 'Username yang dimasukkan sudah digunakan, silakan gunakan username yang berbeda.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Gagal',
          text: error.response?.data?.message || 'Terjadi kesalahan saat menyimpan data.',
        });
      }
    }
  };
  const loadImage = (e) => {
    const image = e.target.files[0];
    setFile(image);
    setPreview(URL.createObjectURL(image));
  };
  return (
    <div>
  <div className="card is-shadowless">
   
      
      <div className="content">
      <p className="subtitle has-text-weight-bold">Tambah Karyawan Baru</p>
        <form onSubmit={saveKaryawan}>
          <p className="has-text-centered has-text-danger">{message}</p>

          {/* Field Username */}
          <div className="field">
            <label className="">Username</label>
            <div className="control has-icons-left">
              <input
                type="text"
                className="input"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Masukkan username"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-user"></i>
              </span>
            </div>
          </div>

          {/* Field Nama Karyawan */}
          <div className="field">
            <label className="">Nama Karyawan</label>
            <div className="control has-icons-left">
              <input
                type="text"
                className="input"
                value={namaLengkap}
                onChange={(e) => setNamaLengkap(e.target.value)}
                placeholder="Masukkan nama lengkap"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-id-badge"></i>
              </span>
            </div>
          </div>

          {/* Field Jabatan */}
          <div className="field">
            <label className="">Jabatan</label>
            <div className="control has-icons-left">
              <input
                type="text"
                className="input"
                value={jabatan}
                onChange={(e) => setJabatan(e.target.value)}
                placeholder="Masukkan jabatan"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-briefcase"></i>
              </span>
            </div>
          </div>

          {/* Field Cabang */}
          <div className="field">
            <label className="">Nama Cabang</label>
            <div className="control">
              <div className="select">
                <select id="cabang" value={cabangId} onChange={(e) => setCabangId(e.target.value)}>
                  <option value="">Pilih Cabang</option>
                  {cabangs && cabangs.map((cabang) => (
                    <option key={cabang.id} value={cabang.id}>{cabang.nama_cabang}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Field Nomor Telepon */}
          <div className="field">
            <label className="">Nomor Telepon</label>
            <div className="control has-icons-left">
              <input
                type="number"
                className="input"
                value={noTelp}
                onChange={(e) => setNoTelp(e.target.value)}
                placeholder="Masukkan nomor telepon"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-phone"></i>
              </span>
            </div>
          </div>

          {/* Field Password */}
          <div className="field">
            <label className="">Password</label>
            <div className="control has-icons-left">
              <input
                type="password"
                className="input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="********"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
            </div>
          </div>

          {/* Field Upload Profile */}
          <div className="field">
            <label className="">Profile</label>
            <div className="control">
              <input type="file" id="file" accept="image/*" onChange={loadImage} />
            </div>
          </div>

          {/* Preview Image */}
          <div className="field">
            {preview && (
              <div className="control">
                <figure className="image is-128x128">
                  <img src={preview} alt="Preview" style={{ borderRadius: '50%' }} />
                </figure>
              </div>
            )}
          </div>

          {/* Save Button */}
          <div className="field">
            <div className="control">
              <button type="submit" className="button is-success  is-fullwidth">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>


  );
};
